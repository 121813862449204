import * as React from "react";

function SvgComponent(): JSX.Element {
	return (
		<svg
			width={71}
			height={40}
			viewBox="0 0 71 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#prefix__clip0)" fill="#2E2D2C">
				<path d="M15.677 2.665h4.887c3.674 0 6.396 1.279 6.396 5.144 0 3.736-2.777 5.417-6.396 5.417h-2.151v6.132H15.68l-.003-16.693zm4.652 8.188c2.492 0 3.687-.997 3.687-3.044 0-2.064-1.249-2.77-3.687-2.77H18.41v5.814h1.918zM29.573 2.665h9.628v2.497h-6.77v4.267h5.583v2.5h-5.582v4.933h7.025v2.497h-9.884V2.665zM42.06 2.683l3.135-.01 4.468 9.364 1.534 3.561h.07c-.13-1.706-.417-3.818-.423-5.685l-.023-7.257 2.844-.01.053 16.695-3.134.01-4.468-9.372-1.533-3.553h-.07c.13 1.776.416 3.788.422 5.636l.024 7.306-2.845.01-.053-16.695zM57.52 17.115c0-1.376.988-2.266 2.238-2.266s2.239.89 2.239 2.266-.989 2.301-2.239 2.301-2.238-.925-2.238-2.301zm.851-12.206L58.282 0h2.953l-.09 4.907-.406 8.215h-1.965l-.403-8.213zM7.023 23.305h3.134l4.437 9.379 1.522 3.566h.07c-.123-1.706-.406-3.821-.406-5.686v-7.259h2.844V40H15.49l-4.434-9.386-1.52-3.558h-.071c.124 1.776.407 3.789.407 5.638V40h-2.85V23.305zM38.758 31.39l-4.64-8.085h3.32l1.982 3.692c.417.766.802 1.54 1.322 2.564h.103a44.028 44.028 0 011.168-2.564l1.895-3.692h3.18l-4.639 8.225L47.405 40H44.07l-2.157-3.934c-.461-.852-.895-1.704-1.413-2.726H40.4a64.995 64.995 0 01-1.298 2.728l-2.119 3.934h-3.18l4.956-8.612zM54.227 25.8h-4.793v-2.495h12.572V25.8H57.21V40h-2.983V25.8zM29.971 30.072h-8.545v2.496h8.545v-2.496zM31.16 23.305h-9.734v2.497h9.734v-2.497zM31.417 37.503h-9.99V40h9.99v-2.497zM6.221 4.941c2.136 0 3.528 2.166 3.528 5.994.005.76-.056 1.517-.182 2.265h2.765c.077-.753.114-1.51.11-2.267 0-5.486-2.325-8.575-6.22-8.575C2.325 2.358 0 5.447 0 10.933c0 5.596 2.33 8.735 6.221 8.735.68.004 1.355-.1 2-.31l-.057-.123-.989-2.314c-.306.11-.629.164-.954.162-2.134 0-3.527-2.216-3.527-6.15 0-3.826 1.393-5.992 3.527-5.992z" />
			</g>
			<defs>
				<clipPath id="prefix__clip0">
					<path fill="#fff" d="M0 0h71v40H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default SvgComponent;
